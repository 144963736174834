import {
    getElement,
    getUID,
    getSelectorFromElement,
    getElementFromSelector,
    getTransitionDurationFromElement,
    triggerTransitionEnd,
    isElement,
    typeCheckConfig,
    isVisible,
    isDisabled,
    findShadowRoot,
    noop,
    getNextActiveElement,
    reflow,
    getjQuery,
    onDOMContentLoaded,
    isRTL,
    defineJQueryPlugin,
    execute,
    executeAfterTransition,
  } from './bootstrap/util/';

/* -------------------------------------------------------------------------- */
/* Navbar                                                                     */
/* -------------------------------------------------------------------------- */

const menuOpen = document.querySelector('[data-bs-toggle="side-navbar"]');
const menuClose = document.querySelector('.menu-closed');
const mainMenu = document.querySelector('.side-navbar'); 
const html = document.querySelector('html');

function openMenu() {
  
  menuClose.classList.toggle('menu-closed--active');

  mainMenu.classList.toggle('side-navbar--open');

  html.classList.toggle('overflow-hidden');
}

menuOpen.addEventListener('click', function() {
  openMenu();
});

